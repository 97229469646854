import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { doNotTransformSettingsKey } from '../helpers';

export const settings = {
  // @deprecated use styleParam instead
  showMemberCount: createSettingsParam('showMemberCount', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // @deprecated use styleParam instead
  showAdminBadge: createSettingsParam('showAdminBadge', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // @deprecated use styleParam instead
  showImage: createSettingsParam('showImage', {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  }),
};

export const styles = {
  groupNameColor: createStylesParam('groupNameColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  groupNameFont: createStylesParam('groupNameFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-XL', { size: 20 }),
  }),
  sidebarGroupNameColor: createStylesParam('sidebarGroupNameColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  sidebarGroupNameFont: createStylesParam('sidebarGroupNameFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  }),
  mobileGroupNameFontSize: createStylesParam('mobileGroupNameFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  }),
  groupNameColorOnHover: createStylesParam('groupNameColorOnHover', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  groupInfoColor: createStylesParam('groupInfoColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  groupInfoFont: createStylesParam('groupInfoFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),
  mobileGroupInfoFontSize: createStylesParam('mobileGroupInfoFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  }),
  groupCardGridTopBottomSpacing: createStylesParam(
    'groupCardGridTopBottomSpacing',
    {
      getDefaultValue: () => 20,
      inheritDesktop: false,
      type: StyleParamType.Number,
    },
  ),
  groupCardGridSideSpacing: createStylesParam('groupCardGridSideSpacing', {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    type: StyleParamType.Number,
  }),
  groupCardListTopBottomSpacing: createStylesParam(
    'groupCardListTopBottomSpacing',
    {
      getDefaultValue: () => 20,
      inheritDesktop: false,
      type: StyleParamType.Number,
    },
  ),
  groupCardListSideSpacing: createStylesParam('groupCardListSideSpacing', {
    getDefaultValue: ({ isMobile }) => {
      if (isMobile) {
        return 0;
      }
      return 20;
    },
    inheritDesktop: false,
    type: StyleParamType.Number,
  }),
  groupCardListWidgetTopBottomSpacing: createStylesParam(
    'groupCardListWidgetTopBottomSpacing',
    {
      getDefaultValue: () => 20,
      inheritDesktop: false,
      type: StyleParamType.Number,
    },
  ),
  groupCardListWidgetSideSpacing: createStylesParam(
    'groupCardListWidgetSideSpacing',
    {
      getDefaultValue: () => 0,
      inheritDesktop: false,
      type: StyleParamType.Number,
    },
  ),
  cardSideBySideHeight: createStylesParam('cardSideBySideHeight', {
    getDefaultValue: () => 241,
    type: StyleParamType.Number,
  }),
  showMemberCount: createStylesParam('showMemberCount', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showAdminBadge: createStylesParam('showAdminBadge', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showImage: createStylesParam('showImage', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
