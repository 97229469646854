export enum Alignment {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum LayoutType {
  grid = 'grid',
  list = 'list',
  listWidget = 'listWidget',
  sideBySide = 'sideBySide',
  sideBar = 'sideBar',
}

export enum ImageRatio {
  rectangle = 'rectangle',
  square = 'square',
}

export enum TextAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum ImageCrop {
  CROP = 'CROP',
  FIT = 'FIT',
}

export enum ImageLayout {
  RTL = 'RTL',
  LTR = 'LTR',
  MIXED = 'MIXED',
}

export enum CoverImageLayout {
  thumbnail = 'thumbnail',
  large = 'large',
  blank = 'blank',
}

export enum MediaLayout {
  grid = 'grid',
  masonry = 'masonry',
  collage = 'collage',
}

export type MediaImagesPerRow = 3 | 4 | 5 | 6;

export enum GroupsRequestSort {
  MEMBERS_COUNT = 'members_count',
  RECENT_ACTIVITY = 'recent_activity_date',
  NAME = 'title',
}

type ComponentName =
  | 'GroupsPage'
  | 'SideBySideWidget'
  | 'GroupListWidget'
  | 'GroupPage'
  | 'FeedWidget';

/**
 * This is a hack that allows you NOT to think about
 * where to import settingsParams/stylesParams from
 * (especially when components have different default values)
 *
 * Every component must define own COMPONENT.name,
 * then in settings/* we can do a switch/case for shared settingsParams/stylesParams
 * in getDefaultValue function based on COMPONENT.name
 *
 */
export const COMPONENT: {
  name?: ComponentName;
} = {
  name: undefined,
};
